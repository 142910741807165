import React, { useState, useEffect } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { useIntl } from "gatsby-plugin-intl"
import closuresStyles from './closures.module.scss'
import Moment from 'react-moment';
import moment from 'moment';
import SanitizedHTML from 'react-sanitized-html';

export default function Closures() {
  const intl = useIntl();
  // Client-side Runtime Data Fetching
  const [courtClosures, setCourtClosures] = useState([]);
  const [courtIndex, setCourtIndex] = useState();
  const closureTranslation =  useStaticQuery(graphql`
    query {
      pageTranslations {
        pageTranslations {
          courtClosuresToday
          localization {
            culture
          }
        }
      }
    }
  `);
  useEffect(() => {
    // https://www.justice.gov/api/v1/eoir_courts.json?pagesize=80
    // prod https://icorapi.eoir.justice.gov/api/justice
    // qc https://icor-api-qc.eoir-aed-ase3gtw.appserviceenvironment.us/api/justice
    // uat https://icor-api-uat.eoir.justice.gov/api/justice
    
    const justiceUrl = 'https://icorapi.eoir.justice.gov/api/justice';
    // const coffeeUrl = 'https://api.sampleapis.com/coffee/hot';
    // for conditional openings (/<p>OPEN\s?<\/p>/)
    const regOpen = new RegExp(/<p>OPEN/);
    // Initialize deferredPrompt for use later to show browser install prompt.
    // let deferredPrompt;

    // window.addEventListener('beforeinstallprompt', (e) => {
    //   // Prevent the mini-infobar from appearing on mobile
    //   e.preventDefault();
    //   // Stash the event so it can be triggered later.
    //   deferredPrompt = e;
    //   // Update UI notify the user they can install the PWA
    //   // showInstallPromotion();
    //   // Optionally, send analytics event that PWA install promo was shown.
    //   console.log(`'beforeinstallprompt' event was fired.`);
    // });

    fetch(justiceUrl)
      .then(response => response.json()) // parse JSON from request
      .then(resultData => {
        // remove Open courts and replace line breaks with spaces
        const allClosed = resultData.Value
                          .filter(court => !regOpen.test(court.status))
                          .map(court => ({...court, status: court.status.replace('<br />', ' ')}))
                          .map(court => ({...court, status: court.status.replace('<a href="/eoir/page/file/1339071/download">Webex Available</a>', '')}))
                          .map(court => ({...court, status: court.status.replace('<a href="/', '<a target="_blank" rel="noopener noreferrer" href="https://www.justice.gov/')}))
        setCourtClosures(allClosed);
        const allCourts = document.querySelector('#court-alert');
        allCourts.firstElementChild.hidden = false;
        setCourtIndex(document.querySelector('#court-alert > div:not([hidden=""]').getAttribute('court-number'));
        if( allClosed.length > 1) {
          document.querySelector('#next-court').addEventListener('click', () => {
              const currentCourt = document.querySelector('#court-alert > div:not([hidden=""]');
              if(currentCourt.nextSibling !== null){
                currentCourt.hidden = true;
                currentCourt.nextSibling.hidden = false;
              } else {
                allCourts.firstElementChild.hidden = false;
                allCourts.lastElementChild.hidden = true;
              }
              setCourtIndex(document.querySelector('#court-alert > div:not([hidden=""]').getAttribute('court-number'));
          });
          document.querySelector('#prev-court').addEventListener('click', () => {
            const currentCourt = document.querySelector('#court-alert > div:not([hidden=""]');
            if(currentCourt.previousSibling !== null){
              currentCourt.hidden = true;
              currentCourt.previousSibling.hidden = false;
            } else {
              allCourts.firstElementChild.hidden = true;
              allCourts.lastElementChild.hidden = false;
            }
            setCourtIndex(document.querySelector('#court-alert > div:not([hidden=""]').getAttribute('court-number'));
          });
        }
        
      }) // set data
    
  }, [])
  function closureText() {
    const pageTranslations = closureTranslation.pageTranslations.pageTranslations;
    const english = pageTranslations.filter(page => page.localization.culture === 'en-US')[0].courtClosuresToday;
    switch (intl.locale) {
      case 'es':
        return pageTranslations.filter(page => page.localization.culture === 'es')[0].courtClosuresToday || english
      case 'zh':
        return pageTranslations.filter(page => page.localization.culture === 'zh')[0].courtClosuresToday || english
      case 'ht':
        return pageTranslations.filter(page => page.localization.culture === 'fr-HT')[0].courtClosuresToday || english
      case 'pt':
        return pageTranslations.filter(page => page.localization.culture === 'pt')[0].courtClosuresToday || english
      case 'pa':
        return pageTranslations.filter(page => page.localization.culture === 'pa')[0].courtClosuresToday || english
      default: 
        return english
    }
  }
  return (
    <section className={closuresStyles.alert}>
        <div className={closuresStyles.banner}>
          <div className={closuresStyles.today}>
            <strong className="margin-right-1">{closureText()}</strong> 
            <Moment format="MMMM DD, yyyy">{moment()}</Moment>
          </div>
          <div className={closuresStyles.court}>
            <div id="court-alert">
                {
                  courtClosures.length > 0 ?
                  courtClosures.map((court, index) => {
                      return(<div key={court.title} court-number={index+1} className={closuresStyles.notice} hidden>
                        <a href={court.url} target="_blank" rel="noopener noreferrer" aria-label="Status"><SanitizedHTML allowedTags={[]} html={court.title}/></a> - <SanitizedHTML allowedTags={['a']} allowedAttributes={{a: [ 'href', 'target', 'rel' ]}} html={court.status}/>
                      </div>)
                  })
                  : <div className={closuresStyles.notice}>
                     <span>All Courts are Open</span>
                    </div>
                }
            </div>
          </div>
          
            <div className={closuresStyles.courtSlider}>
              {
                courtClosures.length > 1 ?
                  <button id="prev-court" className={closuresStyles.arrows} title="previous court">
                    <svg id="a0917ab1-2533-41aa-bdc6-22d7d03771c2" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11.43 13.07" height="13px" width="11px"><title>Left Arrow</title><path d="M6.54,13.07l1.15-1.15L3.13,7.35h8.31V5.72H3.13L7.69,1.15,6.54,0,0,6.53Z"  style={{fill: '#0c0d0e'}}/></svg>
                  </button>
                : ''
              }
              {
                courtClosures.length >= 1 ?
                <span>{ courtIndex } of { courtClosures.length }</span>
                : ''
              }
              {
                courtClosures.length > 1 ?
                  <button id="next-court" className={closuresStyles.arrows} title="next court">
                  <svg id="e0d37b2c-c959-49f3-a97e-f90bb5485aa5" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11.43 13.07" height="13px" width="11px"><title>Right Arrow</title><path d="M4.9,0,3.75,1.15,8.31,5.72H0V7.35H8.31L3.75,11.92,4.9,13.07l6.53-6.54Z" style={{fill: '#0c0d0e'}}/></svg>
                  </button>
                : ''
              }
            </div>
            
        </div>
    </section>
  )
}